import React from 'react';
import styled from 'styled-components';
import liquorBottle from '../Images/liquorBottle.jpg';
import liquorImage from '../Images/bottle.jpg';
import Header from './header';

const HomePageScreen = () => {
  // const phoneNumber = "780-364-0506";
  const phoneNumber = "7803640506";
  return (
    <>
     {/* <Header /> */}
      <MainContainer>
        <HeroContainer>
          <div className="hero-content">
            <span className="heading">EDMONTON DOWNTOWN</span>
            <br />
            <span className="heading">LIQUOR DELIVERY</span>
            <h1 className='sub-heading'>DELIVERING LIQUOR AT YOUR</h1>
            <h1 className='sub-heading'>DOORSTEP IN 15 MINUTES</h1>
            <h2 className="sub-description">WE COVER ALL OVER EDMONTON (DOWNTOWN), WEST EDM MALL, SOUTHSIDE, CLAREVIEWE, SOUTHWEST</h2>
            <CenteredContent>
              <ImageWrapper>
                <img src={liquorImage} alt="Liquor bottles for delivery" />
              </ImageWrapper>
              <CenteredPhoneNumber href={`tel:${phoneNumber}`} title="Call us now">
                <span className="visible">{phoneNumber}</span>
              </CenteredPhoneNumber>
            </CenteredContent>
          </div>
          <ImageWrapper>
            <img src={liquorBottle} alt="Liquor bottle for delivery" />
          </ImageWrapper>
        </HeroContainer>
      </MainContainer>
    </>
  );
};

export default HomePageScreen;

const MainContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  min-height: 100vh;
`;

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 800px;

  .hero-content {
    width: 100%;
  }

  .heading {
    font-size: 52px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: red;
  }

  .sub-heading {
    font-size: 44px;
    font-weight: bold;
    color: #0846A1;
    margin-top: 30px;
    width: 100%;
    color: #0F52BA;
    margin: 10px 0px !important; 
  }
    h1{
    font-size: 44px;
    }
   @media (max-width: 600px) {
   .heading {
     font-size:52px;
    }
    .sub-heading {
     font-size:38px;
    }
     h1{
     font-size:40px;
     }
  }
       h2 {
    font-size: 22px;
    color: yellow;
    background-color: #008080;
    border: 2px solid darkblue;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
  }
  @media (max-width: 500px) {
    .hero-content {
      text-align: left;
    }
    .heading{
     font-size: 46px;
    }
      .sub-heading{
     font-size: 40px;
    }
    h1{
     font-size: 38px;
    }
    .heading,
    .sub-heading {
      text-align: left;
    }
  }
  @media (max-width: 470px) {
   .heading {
     font-size:44px;
    }
    .sub-heading {
     font-size:28px;
     text-align: left;
    }
  }
  @media (max-width: 680px) {
   .hero-content {
      text-align: left;
    }
  }
  @media (max-width: 423px) {
   .heading {
     font-size:36px;
    }
    .sub-heading {
     font-size:28px;
    }
     h1{
     font-size:28px;
     }
     h2{
     font-size:22px;
     }
  }
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

// const CenteredPhoneNumber = styled.a`
//   font-size: 2rem;
//   color: #fff;
//   background-color: #0846A1;
//   padding: 20px;
//   border-radius: 10px;
//   text-decoration: none;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   max-width: 400px;
//   font-weight: 700;
//   transition: background-color 0.3s ease, transform 0.3s ease;

//   &:hover {
//     background-color: #218838;
//     transform: scale(1.05);
//   }

//   &:active {
//     transform: scale(0.95);
//   }
// `;
const CenteredPhoneNumber = styled.a`
  font-size: 2rem;
  color: #fff;
  background-color: #0846A1;
  padding: 20px;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  font-weight: 700;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #218838;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  .visible {
    font-weight: bold;
    color: #f0f0f0;
  }

  .masked {
    margin-left: 5px;
    color: #aaa;
  }
`;
